// Include Sass Files
@import "inc/variables/colors.scss";
@import "inc/variables/fonts.scss";
@import "inc/variables/others.scss";

@import "inc/mixins/media-query.scss";
@import "inc/mixins/flex.scss";


@include for-phone-up{

    #footer{
        padding-top: 30px;
        .quiz{
            width: 100%;
            height: 16px;
            overflow: hidden;
            white-space: nowrap;
            position: relative;
            padding-bottom: 40px;

            .track {
                position: absolute;
                white-space: nowrap;
                will-change: transform;
                animation: r-marquee 20s linear infinite;
            }

            p{
                font-size: 12px;
                color: #000;
                height: 12px;
                font-family: "HelveticaNeueRegular";
                margin: 0 auto;
            }
        }

        .brands{
            background-color: #000;
            padding-top: 20px;
            padding-bottom: 20px;

            .footer-title{
                h4{
                    font-size: 14px;
                    width: 100%;
                    text-align: center;
                    color: #FFF;
                    font-family: "HelveticaNeueMedium";
                    margin-bottom: 10px;
                }
            }
            .footer-logos{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;

                a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: nowrap;
                    img{
                        max-width: 100%;
                        position: relative;
                    }
                }
            }

        }
    }


}

@include for-tablet-up{

    #footer{        

        .brands{
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }


}

@include for-desktop-up{

    #footer{
        padding-top: 40px;

        
    }


}

@include for-tv-up{

    #footer{
        padding-top: 40px;

        
    }


}