/*
	Informacoes do Arquivo
	============================================================

	Arquivo.........: media-query.scss
	Versao..........: 1.0
	Data de Criacao.: 01/01/2020

	============================================================
*/


@mixin for-phone-up {
    @media (min-width: 0px) { @content; }
}

@mixin for-tablet-up {
    @media (min-width: 600px) { @content; }
}

@mixin for-desktop-up {
    @media (min-width: 992px) { @content; }
}

@mixin for-tv-up {
    @media (min-width: 1500px) { @content; }
}